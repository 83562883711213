<messages>["./Contact"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-container fluid>
    <contact-create-update
      is-create
      :contact-data="{contact: {
        handle,
        registryType: registry
      }}"
      @success="onSuccess"
      @dataValidityChanged="onDataValidityChanged"
      @cancel="back"/>

    <v-alert
      v-t="'general.invalid.form'"
      type="error"
      :value="isInvalidDataAlertVisible"/>
  </v-container>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapMutations} from 'vuex'
  import ContactCreateUpdate from './ContactCreateUpdate'

  export default {
    name: 'ContactClone',

    components: {
      ContactCreateUpdate
    },

    props: {
      handle: {type: String, required: true},
      registry: {type: String, required: true}
    },

    data () {
      return {
        isInvalidDataAlertVisible: false
      }
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      onSuccess (newContact) {
        this.displaySuccessMessage (this.$t ('create.success',
                                             {link: newContact.handle}))

        this.back ()
      },

      onDataValidityChanged (isValid) {
        this.isInvalidDataAlertVisible = !isValid
      },

      back () {
        this.$router.back ()
      }
    }
  }
</script>
